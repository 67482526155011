import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Alert, Tooltip, Button, TextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as requests from 'store/actions/requests';

const Debug = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await requests.getUsers();
        console.log(response.data);
        setUsers(response.data);
      } catch (err) {
        setError('Erreur lors de la récupération des utilisateurs');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);



  const getLatestLogs = async () => {
    try {
      const response = await requests.getLatestBuildLogs();
      setLogs(response.data.logs);
    } catch (err) {
      setError('Erreur lors de la récupération des logs');
    }
  };

  const analyzeLogs = async () => {
    try {
      const response = await requests.analyzeLogs(logs);
      setAnalysis(response.data.analysis);
    } catch (err) {
      setError('Erreur lors de l\'analyse des logs');
    }
  };

  const filteredLogs = logs.split('\n').filter(line => line.toLowerCase().includes(searchTerm.toLowerCase())).join('\n');

  if (isLoading) return <div className="p-4">Chargement...</div>;
  if (error) return <Alert severity="error" className="m-4">{error}</Alert>;

  return (
    <div className="p-4">
      <Card className="mb-6 shadow-lg">
        <CardHeader title="Page de Débogage" className="bg-gray-100 text-2xl font-bold" />
        <CardContent className="p-4">
          <div className="mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={getLatestLogs}
            >
              Obtenir les derniers logs
            </button>
            <button
              className={`bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded ${
                !logs ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={analyzeLogs}
              disabled={!logs}
            >
              Analyser les logs
            </button>
          </div>
          {logs && (
            <div className="mb-4">
              <TextField
                label="Rechercher dans les logs"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mb-2"
              />
              <div style={{ height: '300px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px' }}>
                <pre>{filteredLogs}</pre>
              </div>
            </div>
          )}
          {analysis && (
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Analyse des logs</h3>
              <pre>{analysis}</pre>
            </div>
          )}
          <h3 className="text-xl font-semibold mb-4">Utilisateurs avec Plusieurs Abonnements</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {users.map((user) => (
              <Card key={user.id} className="shadow-md hover:shadow-lg transition-shadow duration-300">
                <CardContent className="p-4">
                  <h4 className="text-lg font-semibold mb-2">{user.email}</h4>
                  <p className="text-sm text-gray-600">École: {user.profile.school ? user.profile.school.name : 'N/A'}</p>
                  <p className="text-sm text-gray-600">Créé le: {new Date(user.date_joined).toLocaleDateString()}</p>
                  <h5 className="text-md font-semibold mt-4 mb-2">Abonnements</h5>
                  <div className="space-y-4">
                    {user.subscription_tracks.map((subscription) => (
                      <div key={subscription.id} className="bg-blue-50 p-4 rounded-lg border border-blue-200">
                        <p className="font-medium text-blue-700">{subscription.track.name}</p>
                        <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                          <p className="text-gray-600">Début: <span className="font-semibold">{subscription.start_date ? new Date(subscription.start_date).toLocaleDateString() : 'N/A'}</span></p>
                          <p className="text-gray-600">Fin: <span className="font-semibold">{subscription.cancel_at ? new Date(subscription.cancel_at).toLocaleDateString() : 'N/A'}</span></p>
                          <p className="text-gray-600">
                            Statut: 
                            <span className={`font-semibold ${subscription.is_active ? 'text-green-600' : 'text-red-600'}`}>
                              {subscription.is_active ? 'Actif' : 'Inactif'}
                            </span>
                            {!subscription.is_active && (
                              <Tooltip title="L'utilisateur n'a pas commencé (et start n'a pas été appelé)" arrow>
                                <InfoIcon fontSize="small" className="ml-1 text-gray-500" />
                              </Tooltip>
                            )}
                          </p>
                          <p className="text-gray-600">Commencé: <span className="font-semibold">{subscription.has_started ? 'Oui' : 'Non'}</span></p>
                          <p className="text-gray-600">Crédits: <span className="font-semibold">{subscription.credits_spend}</span></p>
                          <p className="text-gray-600">Jours restants: <span className="font-semibold">{subscription.remaining_time_days !== null ? subscription.remaining_time_days : 'N/A'}</span></p>
                          <p className="text-gray-600 col-span-2">
                            Débloqué: 
                            <span className="font-semibold">{subscription.unlocked ? 'Oui' : 'Non'}</span>
                            <Tooltip title="L'école a payé pour cet abonnement" arrow>
                              <InfoIcon fontSize="small" className="ml-1 text-gray-500" />
                            </Tooltip>
                          </p>
                          <p className="text-gray-600 col-span-2">
                            Cours:
                            <span className="font-semibold">
                              {subscription.track.universes ? subscription.track.universes.map(universe => universe.name).join(', ') : 'Aucun'}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Debug;