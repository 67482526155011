import React, { useState, useEffect } from 'react';
import * as requests from 'store/actions/requests';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const LandingDemo = ({ subDomain }) => {
  useEffect(() => {
    requests.createUserDemo(subDomain).then((res) => {
      //   TODO update the context directly
      window.location.href = '/';
    });
  }, [subDomain]);

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LandingDemo;
