import { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import 'assets/scss/App.scss';
import UserContext from 'store/UserContext';

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Debug from 'SigninPages/Debug/Debug';
import LandingDemo from './SignoutPages/Demo/LandingDemo';
import TestLevel from "./SignoutPages/TestLevel/TestLevel"
import { Mixpanel } from './MixPanel';
import * as requests from './store/actions/requests';

const Signup = lazy(() => import('SignoutPages/Signup'));
const LoginPage = lazy(() => import('SignoutPages/LoginPage'));

const ForgotPass = lazy(() => import('SignoutPages/ForgotPass'));
const NotFound = lazy(() => import('./components/errorPages/404'));
const MobilePage = lazy(() => import('./components/errorPages/mobilePage'));

const Universe = lazy(() => import('./SigninPages/Universe/Universe'));
const Exercice = lazy(() => import('./SigninPages/Exercise/Exercice'));
const Road = lazy(() => import('./SigninPages/Dashboard/Road'));
const ExerciceQuizz = lazy(() => import('./SigninPages/Exercise/ExerciceQuizz'));
const Profile = lazy(() => import('./SigninPages/Profile/Profile'));

const DashboardB2B = lazy(() => import('./SigninPages/DashboardB2B/DashboardB2B'));
const RapportTable = lazy(() =>
  import('./SigninPages/DashboardB2B/components/StudentTable/RapportTable'),
);
const SessionTable = lazy(() =>
  import('./SigninPages/DashboardB2B/components/StudentTable/SessionTable'),
);
const ActivityTable = lazy(() =>
  import('./SigninPages/DashboardB2B/components/StudentTable/ActivityTable'),
);
const AlertTable = lazy(() =>
  import('./SigninPages/DashboardB2B/components/StudentTable/AlertTable'),
);

const Parameters = lazy(() => import('./SigninPages/Parameters/Parameters'));
const AboutUs = lazy(() => import('./SignoutPages/AboutUs/AboutUs'));
const LandingPage = lazy(() => import('./SignoutPages/LandingPage/LandingPage'));
const SubscriptionPro = lazy(() => import('./SignoutPages/SubscriptionPro/SubscriptionPro'));
const AddQuiz = lazy(() => import('./SigninPages/Dashboard/Admin/AddQuiz.tsx'));
const AddUnivers = lazy(() => import('./SigninPages/Dashboard/Admin/AddUnivers.tsx'));
window.SYSTEM_INFORMATION = window.SYSTEM_INFORMATION ? window.SYSTEM_INFORMATION : {};
Sentry.init({
  dsn: window.SYSTEM_INFORMATION.SENTRY_DSN,
  release: `stanley@${process.env.npm_package_version}`,
  integrations: [new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.1, // Be sure to lower this in production
});
const theme = createTheme();

const storageHelper = {
  set: (key, value) => {
    return localStorage.setItem(key, value);
  },
  remove: (key) => {
    return localStorage.removeItem(key);
  },
  get: (key) => {
    return localStorage.getItem(key);
  },
};

const App = () => {
  const loggedInUser = localStorage && localStorage.getItem('user_login');

  let subDomain = window.location.hostname
    .replace('www.', '')
    .replace('dhaki.fr', '')
    .replace('appforma.fr', '')
    .replace('.', '');
  if (subDomain === 'localhost' || subDomain === 'test') subDomain = '';

  const [user, setUser] = useState();

  const isApiCallNeeded = loggedInUser === 'true' && !user;
  useEffect(() => {
    const getUserInfo = () => {
      requests
        .getCurrentUserInfo()
        .then((res) => {
          setUser({ ...res.data });
          Mixpanel.identify(res.data.email);
          Mixpanel.track(`${window.location.pathname}`);
        })
        .catch(() => {
          // TODO add condition to know if this is a second new visit for the same session id
          // Mixpanel.track(`landing-page`);
          // Mixpanel.register({ 'First Time': 'FALSE' });

          Mixpanel.track(`landing-page`);
          storageHelper.remove('user_login');
          Mixpanel.register({ 'First Time': 'FALSE' });
        });
    };
    if (isApiCallNeeded) {
      getUserInfo();
    }
    return function cleanup() {};
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={user}>
          <div id="app-route">
            {/* remove any dhaki branding for schools, nothing as default */}
            {!subDomain && (
              <Helmet>
                <link rel="shortcut icon" href="static/favicon.ico" />
                <meta property="og:site_name" content="Dhaki" />
                <meta
                  name="description"
                  content="Pour un abonnement mensuel, tu peux
    accéder à formations complètes avec vidéos sur des langages comme Python,
    HTML, CSS, Javacript, ou encore des frameworks comme React, Django. Le tout
    depuis ton navigateur !/"
                />
                <title>Apprends à coder en ligne</title>
                <meta property="og:description" content="Faites du code une passion" />
                <meta property="og:url" content="https://www.dhaki.fr" />
                <meta
                  property="og:image"
                  content="https://i.ibb.co/DV2BHNR/Screen-Shot-2019-12-15-at-12-42-17-AM.png"
                />
              </Helmet>
            )}

            <BrowserRouter>
              <Suspense fallback="">
                <Switch>
                  
                <Route path="/cours/:universId/:chapterId?/:exerciceId?" component={AddQuiz} />
                <Route exact path="/cours" render={(props)  => <AddUnivers {...props} user={user} />} />
                  <Route path="/test-level" component={TestLevel} />
                  <Route
                    exact
                    path="/debug"
                    render={(props) => <Debug {...props} />}
                  />
                  <Route exact path="/404" component={NotFound} />
                  <Route exact path="/mobile" component={MobilePage} />
                  <Route
                    path="/:univers_identifier/quizz/:id"
                    render={(props) => (
                      <ExerciceQuizz
                        chapterId={props.match.params.id}
                        key={props.match.params.id}
                      />
                    )}
                  />
                  <Route
                    path="/:univers_identifier/exercice/:id"
                    render={(props) => {
                      return !user && !isApiCallNeeded ? (
                        <Redirect to="/" />
                      ) : (
                        <Exercice
                          key={props.match.params.id}
                          openVideo={props.location.search}
                          exerciceId={props.match.params.id}
                          user={user}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/exercice_demo/:id"
                    render={(props) => (
                      <Exercice exerciceId={props.match.params.id} key={props.match.params.id} />
                    )}
                  />
                  <Route
                    exact
                    path="/profile"
                    render={(props) =>
                      user ? <Profile {...props} user={user} /> : <Redirect to="/" />
                    }
                  />
                  <Route
                    exact
                    path="/parameters"
                    render={(props) => <Parameters {...props} user={user} />}
                  />

                  <Route exact path="/landing" component={LandingPage} />
                  <Route
                    exact
                    path="/subscription-pro"
                    render={(props) => <SubscriptionPro {...props} />}
                  />
                  <Route
                    exact
                    path="/"
                    render={(props) => {
                      if (
                        user &&
                        user.profile &&
                        user.profile.school &&
                        user.profile.school.owner === user.id
                      )
                        return <Redirect to={`/school/${user.profile.school.id}/`} />;
                      if (user) return <Redirect to="/dashboard/" />;
                      if (isApiCallNeeded) return null;
                      if (subDomain) return <LoginPage />;
                      return <LandingPage {...props} />;
                    }}
                  />
                  <Route
                    path="/students"
                    render={({ match: { url } }) => {
                      return (
                        <>
                          <Route path="/students/rapport/:user_id" component={RapportTable} />
                          <Route path="/students/session/:user_id" component={SessionTable} />
                          <Route path="/students/activity/:user_id" component={ActivityTable} />
                          <Route path="/students/alert/:user_id" component={AlertTable} />
                        </>
                      );
                    }}
                  />
                  <Route
                    path="/school/:id/"
                    render={(props) => {
                      return <DashboardB2B schoolId={props.match.params.id} />;
                    }}
                  />

                  <Route
                    path="/dashboard/"
                    render={(props) => {
                      return <Road />;
                    }}
                  />
                  <Route exact path="/about" component={AboutUs} />
                  <Route
                    path="/login"
                    render={(props) => (
                      <>
                        <LoginPage {...props} />
                      </>
                    )}
                  />
                  <Route
                    path="/signup"
                    render={(props) => (
                      <>
                        <Signup {...props} />
                      </>
                    )}
                  />
                  <Route
                    path="/reset-password"
                    render={(props) => (
                      <>
                        <ForgotPass {...props} />
                      </>
                    )}
                  />

                  <Route path="/demo" render={(props) => <LandingDemo subDomain={subDomain} />} />
                  <Route
                    path="/univers/:identifier"
                    render={(props) => (
                      <Redirect
                        to={`/${props.match.params.identifier}`}
                        key={props.match.params.identifier}
                      />
                    )}
                  />
                  <Route
                    path="/:identifier"
                    render={(props) =>
                      !user && !isApiCallNeeded ? (
                        <Redirect to="/" />
                      ) : (
                        <Universe
                          universIdentifier={props.match.params.identifier}
                          key={props.match.params.identifier}
                          user={user}
                        />
                      )
                    }
                  />
                </Switch>
              </Suspense>
            </BrowserRouter>
          </div>
        </UserContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
