import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';

mixpanel.init(
  window.SYSTEM_INFORMATION ? window.SYSTEM_INFORMATION.MIXPANEL_TOKEN : '.MIXPANEL_TOKEN',
);

const actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => mixpanel.alias(id),
  track: (name, props) => mixpanel.track(name, props),
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
  distinct_id: () => mixpanel.get_distinct_id(),
  reset: () => mixpanel.reset(),
  register: (properties) => mixpanel.register(properties),
};

export const Mixpanel = actions;
export const mixpanelA = mixpanel;

// GTM-TZ7DDHB
ReactGA.initialize('UA-178384936-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default ReactGA;
