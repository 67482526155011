import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import questions from './questions.json';
import recommendations from './recommendations.json';
import dhaki_logo from 'assets/img/landing/DHAKI_LOGO.png';
import SideBar from './SideBar.tsx';

const TestLevel = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [email, setEmail] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showReport, setShowReport] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [showTest, setShowTest] = useState(false);
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes in seconds

  const languages = [
    { value: 'python', label: 'Python' },
    { value: 'javascript', label: 'JavaScript' },
    { value: 'java', label: 'Java' },
    { value: 'html_css', label: 'HTML/CSS' }
  ];

  useEffect(() => {
    if (showTest && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      handleFinish();
    }
  }, [showTest, timeLeft]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setEmailError('');

    if (!validateEmail(email)) {
      setEmailError('Veuillez entrer une adresse email valide');
      return;
    }

    if (!selectedLanguage) {
      setEmailError('Veuillez sélectionner un langage');
      return;
    }

    setShowTest(true);
    setCurrentQuestion(0);
    setShowReport(false);
    setAnswers(new Array(questions[selectedLanguage].length).fill(null));
  };

  const handleLanguageSelect = (option) => {
    setSelectedLanguage(option.value);
  };

  const handleAnswerSelect = (answerIndex) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = answerIndex;
    setAnswers(newAnswers);
  };

  const handleNext = () => {
    if (currentQuestion < questions[selectedLanguage].length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleFinish = () => {
    if (!answers.includes(null)) {
      setShowReport(true);
    } else {
      alert('Veuillez répondre à toutes les questions avant de terminer le test');
    }
  };

  const calculateScoreByConcept = () => {
    const conceptScores = {};
    
    answers.forEach((answer, index) => {
      const question = questions[selectedLanguage][index];
      const concept = question.concept;
      
      if (!conceptScores[concept]) {
        conceptScores[concept] = { correct: 0, total: 0 };
      }
      
      conceptScores[concept].total++;
      if (answer === question.correct) {
        conceptScores[concept].correct++;
      }
    });

    return conceptScores;
  };

  const getRecommendations = (conceptScores) => {
    const results = [];
    
    Object.entries(conceptScores).forEach(([concept, score]) => {
      const percentage = (score.correct / score.total) * 100;
      const conceptRecs = recommendations[selectedLanguage][concept];
      
      if (percentage < 50 && conceptRecs.low) {
        results.push({
          concept,
          message: conceptRecs.low,
          level: 'low'
        });
      } else if (percentage < 80 && conceptRecs.medium) {
        results.push({
          concept,
          message: conceptRecs.medium,
          level: 'medium'  
        });
      } else if (conceptRecs.high) {
        results.push({
          concept,
          message: conceptRecs.high,
          level: 'high'
        });
      }
    });

    return results;
  };

  if (!showTest) {
    return (
      <div className="flex min-h-screen bg-gray-100">
        <div className="w-1/5 h-screen bg-gradient-to-br from-indigo-600 to-purple-600">
          <SideBar />
        </div>

        <div className="w-4/5 p-12 flex justify-center items-center bg-white">
          <div className="w-full max-w-3xl bg-white p-8 rounded-2xl shadow-xl">
            <img src={dhaki_logo} alt="Dhaki Logo" className="block mx-auto mb-8 w-44 animate-bounce" />
            <h1 className="text-center mb-8 text-2xl font-semibold text-gray-800">Test de Niveau en Programmation</h1>

            <div className="bg-gradient-to-r from-blue-400 to-blue-500 p-7 rounded-xl mb-8 text-white shadow-lg">
              <p>
                Ce test évalue vos compétences en programmation à travers une série de questions ciblées.
                Il comprend {selectedLanguage ? questions[selectedLanguage].length : '3'} questions et prend environ 15 minutes à compléter.
              </p>
            </div>

            <form onSubmit={handleEmailSubmit} className="space-y-8">
              <div className="flex flex-col gap-2">
                <label htmlFor="email" className="text-gray-700">Email</label>
                <input
                  id="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="votre@email.com"
                  className="p-4 border-2 border-gray-200 rounded-lg focus:border-indigo-600 focus:ring-2 focus:ring-indigo-200 outline-none transition duration-300"
                />
              </div>
           
              <div className="space-y-3">
                <label htmlFor="language-grid" className="text-gray-700">Choisissez un Langage de Programmation</label>
                <div id="language-grid" className="grid grid-cols-3 gap-5">
                  {languages.map(lang => (
                    <button
                      key={lang.value}
                      type="button"
                      className={`p-5 rounded-xl transition duration-300 transform hover:-translate-y-1 shadow-md ${
                        selectedLanguage === lang.value 
                        ? 'bg-gradient-to-r from-teal-400 to-teal-500 text-white shadow-lg' 
                        : 'bg-gray-100 text-gray-800 hover:shadow-lg'
                      }`}
                      onClick={() => handleLanguageSelect({value: lang.value})}
                    >
                      {lang.label}
                    </button>
                  ))}
                </div>
              </div>

              {emailError && (
                <div className="text-red-600 text-sm font-medium p-2 bg-red-50 rounded-lg">
                  {emailError}
                </div>
              )}

              <button
                type="submit"
                disabled={!email || !selectedLanguage}
                className={`w-full p-5 rounded-xl text-lg font-semibold uppercase tracking-wide transition duration-300 transform hover:-translate-y-1 ${
                  !email || !selectedLanguage 
                  ? 'bg-gray-300 cursor-not-allowed' 
                  : 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white shadow-lg hover:shadow-xl'
                }`}
              >
                Commencer le Test
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (showReport) {
    const conceptScores = calculateScoreByConcept();
    const recommendations = getRecommendations(conceptScores);
    const totalScore = (answers.filter((answer, index) => answer === questions[selectedLanguage][index].correct).length / questions[selectedLanguage].length) * 100;

    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-white/90 backdrop-blur-sm rounded-lg shadow-xl p-8">
          <img src={dhaki_logo} alt="Dhaki Logo" className="mx-auto h-16 mb-8 animate-pulse" />
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">Votre Rapport d&apos;Évaluation</h2>
          
          <div className="mb-8">
            <p className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 text-center mb-4">
              {Math.round(totalScore)}%
            </p>
            <p className="text-xl text-center mb-8">Score Global</p>
            
            <div className="space-y-6">
              <h3 className="text-xl font-semibold">Résultats par Concept:</h3>
              {Object.entries(conceptScores).map(([concept, score]) => (
                <div key={concept} className="bg-white/50 p-4 rounded-lg backdrop-blur-sm">
                  <div className="flex justify-between mb-2">
                    <span className="font-medium">{concept}</span>
                    <span className="font-medium">{Math.round((score.correct / score.total) * 100)}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-gradient-to-r from-purple-500 to-pink-500 h-2 rounded-full transition-all duration-500"
                      style={{
                        width: `${(score.correct / score.total) * 100}%`,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            {recommendations.length > 0 && (
              <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4">Recommandations:</h3>
                <div className="space-y-4">
                  {recommendations.map((rec) => (
                    <div key={rec.concept} 
                      className={`p-4 rounded-lg backdrop-blur-sm ${
                        rec.level === 'low' ? 'bg-red-50/90 text-red-800' :
                        rec.level === 'medium' ? 'bg-yellow-50/90 text-yellow-800' :
                        'bg-green-50/90 text-green-800'
                      }`}>
                      <p>{rec.message}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <button
              type="button"
              onClick={() => {
                setSelectedLanguage('');
                setShowTest(false);
              }}
              className="mt-8 bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-2 rounded-lg hover:from-purple-600 hover:to-pink-600 transition-all duration-200 w-full transform hover:scale-105"
            >
              Essayer un Autre Langage
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white/90 backdrop-blur-sm rounded-lg shadow-xl p-8">
        <img src={dhaki_logo} alt="Dhaki Logo" className="mx-auto h-16 mb-8" />
        <div className="mb-8">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Question {currentQuestion + 1} sur {questions[selectedLanguage].length}
            </h2>
            <div className="bg-gradient-to-r from-purple-500 to-pink-500 p-3 rounded-lg text-white">
              <div>
                <span>{formatTime(timeLeft)}</span>
              </div>
            </div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2 mt-4">
            <div
              className="bg-gradient-to-r from-purple-500 to-pink-500 h-2 rounded-full transition-all duration-500"
              style={{
                width: `${((currentQuestion + 1) / questions[selectedLanguage].length) * 100}%`,
              }}
            />
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            {questions[selectedLanguage][currentQuestion].question}
          </h3>
          <div className="space-y-4">
            {questions[selectedLanguage][currentQuestion].options.map((option, index) => (
              <button
                type="button"
                key={option}
                onClick={() => handleAnswerSelect(index)}
                className={`w-full text-left p-4 border rounded-lg transition-all duration-200 transform hover:scale-102 ${
                  answers[currentQuestion] === index ? 'border-purple-500 bg-purple-50 shadow-lg' : 'hover:bg-gray-50'
                }`}
              >
                <SyntaxHighlighter language={selectedLanguage} style={atomDark}>
                  {option}
                </SyntaxHighlighter>
              </button>
            ))}
          </div>
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={handlePrevious}
            disabled={currentQuestion === 0}
            className="px-6 py-2 bg-gradient-to-r from-gray-400 to-gray-500 text-white rounded-lg hover:from-gray-500 hover:to-gray-600 disabled:opacity-50 transition-all duration-200"
          >
            Précédent
          </button>
          
          {currentQuestion === questions[selectedLanguage].length - 1 ? (
            <button
              type="button"
              onClick={handleFinish}
              className="px-6 py-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-lg hover:from-purple-600 hover:to-pink-600 transform hover:scale-105 transition-all duration-200"
            >
              Terminer
            </button>
          ) : (
            <button
              type="button"
              onClick={handleNext}
              disabled={answers[currentQuestion] === null}
              className="px-6 py-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-lg hover:from-purple-600 hover:to-pink-600 disabled:opacity-50 transform hover:scale-105 transition-all duration-200"
            >
              Suivant
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestLevel;
