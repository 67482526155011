import React from 'react';
import { motion } from 'framer-motion';

const SideBar = () => {
  const languages = [
    { name: 'Python', color: '#3572A5' },
    { name: 'JavaScript', color: '#F7DF1E' }, 
    { name: 'HTML/CSS', color: '#E34F26' },
    { name: 'Java', color: '#B07219' }
  ];

  const concepts = [
    { name: 'Types de Données', color: '#FF6B6B' },
    { name: 'POO', color: '#4ECDC4' },
    { name: 'Promesses', color: '#45B7D1' },
    { name: 'Event Loop', color: '#96CEB4' },
    { name: 'Box Model', color: '#FF9F1C' },
    { name: 'Concurrence', color: '#9B5DE5' },
    { name: 'Gestion Mémoire', color: '#F15BB5' },
    { name: 'Design Patterns', color: '#00BBF9' }
  ];

  const generateParticles = (count) => {
    return Array.from({ length: count }, (_, i) => {
      const isLanguage = Math.random() > 0.5;
      const source = isLanguage ? languages : concepts;
      return {
        id: i,
        x: Math.random() * 100,
        delay: Math.random() * 2,
        scale: 0.5 + Math.random() * 0.5,
        item: source[Math.floor(Math.random() * source.length)]
      };
    });
  };

  const fireParticles = generateParticles(16);

  return (
    <div className="w-full h-full relative overflow-hidden bg-gradient-to-br from-[#1a1a2e] to-[#16213e]">
      {/* Fire base */}
      <div className="absolute bottom-0 left-0 right-0 h-[150px] bg-gradient-to-t from-[#ff4d4d] to-transparent blur-sm opacity-70" />

      {/* Language and concept particles */}
      {fireParticles.map((particle) => (
        <motion.div
          key={particle.id}
          className="absolute bottom-0 flex items-center justify-center p-2 px-3 text-xs font-bold text-white rounded-lg shadow-lg cursor-pointer whitespace-nowrap z-10"
          style={{
            left: `${particle.x}%`,
            background: particle.item.color,
          }}
          initial={{ 
            y: 100,
            opacity: 0,
            scale: particle.scale 
          }}
          animate={{
            y: -window.innerHeight,
            opacity: [0, 1, 0],
            scale: [particle.scale, particle.scale * 1.2, particle.scale * 0.8]
          }}
          whileHover={{
            scale: particle.scale * 1.4,
            transition: { duration: 0.2 }
          }}
          transition={{
            duration: 10 + Math.random() * 5,
            repeat: Infinity,
            delay: particle.delay,
            ease: "linear"
          }}
        >
          {particle.item.name}
        </motion.div>
      ))}

      {/* Glowing embers */}
      {Array.from({ length: 40 }).map((_, i) => (
        <motion.div
          key={`ember-${i}`}
          className="absolute bottom-0 w-1 h-1 bg-[#ffa502] rounded-full z-[1]"
          style={{
            left: `${Math.random() * 100}%`,
          }}
          initial={{ y: 100, opacity: 0 }}
          animate={{
            y: [-100, -window.innerHeight * 0.6],
            opacity: [0, 1, 0],
            x: [0, (Math.random() - 0.5) * 100]
          }}
          transition={{
            duration: 15 + Math.random() * 10,
            repeat: Infinity,
            delay: Math.random() * 2,
            ease: "easeInOut"
          }}
        />
      ))}
    </div>
  );
};

export default SideBar;
